import React from "react";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const Loader = ({ type = "circular", message = "", fullHeight = true, size = 40 }) => (
  <Grid
    container
    direction="row"
    justifyContent="center"
    alignItems="center"
    style={{ height: fullHeight === true ? (type === "linear" ? "100vh": "100%") : null, padding: "5em", display: "flex", flexDirection: "column" }}
  >
    {message && <Typography variant="body2">{message}</Typography>}
    {type === "linear" && <LinearProgress style={{ marginTop: message ? "15px" : 0, width: "30%" }} />}
    {type === "circular" && <CircularProgress style={{ width: size, height: size, marginTop: message ? "15px" : 0 }} />}
  </Grid>
);

export default Loader;
