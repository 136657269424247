import React, { useState, } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";

const Loader = ({
  message = "",
  onRetry = () => {
    window.location.reload();
  }
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", padding: "5em", display: "flex", flexDirection: "column" }}
    >
      {message && <Typography variant="body2">{message}</Typography>}

      {isLoading ? <LinearProgress style={{ marginTop: message ? "15px" : 0, width: "30%" }} /> :
        <Button disabled={isLoading} variant="contained" color="primary" onClick={() => {
          setIsLoading(true);
          onRetry();
        }} style={{ marginTop: message ? "15px" : 0, width: "30%" }}>
         Tentar novamente
        </Button>}
    </Grid>
  );
};

export default Loader;
