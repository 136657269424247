import i18n from "i18next";
import { initReactI18next, } from "react-i18next";
import enTranslation from "./translations/en/resource.json";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    title: "English",
    translation: enTranslation,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "pt-BR",
    keySeparator: false,
    interpolation: { escapeValue: false },
    detection: { order: ["navigator"] }
  });

export default i18n;
