import React, { useContext, lazy, Suspense, } from "react";
import ReactDOM from "react-dom";
import FirebaseContext, { FirebaseProvider, } from "contexts/firebase";
import Loader from "components/Loader";
import ErrorWithRetry from "components/ErrorWithRetry";
import "./i18n";

const App = lazy(() => import("./App"));

const WithFirebase = () => {
  const firebaseContext = useContext(FirebaseContext);

  if (firebaseContext.firebaseConfig === undefined) {
    return <Loader type="linear" message="Inicializando plataforma..." />;
  } else if (firebaseContext.firebaseConfig === false) {
    return (
      <ErrorWithRetry message="Ocorreu um erro ao inicializar a plataforma. Tente novamente." />
    );
  }

  return (
    <Suspense fallback={<Loader type="linear" message="Ambiente iniciado" />}>
      <App />
    </Suspense>
  );
};

ReactDOM.render(
  <FirebaseProvider>
    <WithFirebase />
  </FirebaseProvider>,
  document.getElementById("root")
);
