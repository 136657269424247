import React, { useState, useEffect, } from "react";
import ky from "ky";
import firebase from "firebase/app";

import "firebase/auth";
import "firebase/app-check";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

const FirebaseContext = React.createContext();

const FirebaseProvider = ({ children }) => {
  const [firebaseConfig, setFirebaseConfig] = useState();

  useEffect(() => {
    console.log("%cIniciando ambiente de telemedicina...", "font-weight: bold; color: blue");
    console.log(`%cModo do ambiente: %c${process.env.NODE_ENV}`, "font-weight: bold", "color: gray");

    try {
      if (["development", "test"].includes(process.env.NODE_ENV)) {
        console.log("%cAmbiente detectado: %cDesenvolvimento", "font-weight: bold", "color: red; font-weight: bold");
        setFirebaseConfig({
          apiKey: "AIzaSyA7OjFvXmhdZXfI6CCAE5fzRJNSzRrmHRk",
          authDomain: "interconsulta-8202b.firebaseapp.com",
          databaseURL: "https://interconsulta-8202b-default-rtdb.firebaseio.com",
          projectId: "interconsulta-8202b",
          storageBucket: "interconsulta-8202b.appspot.com",
          messagingSenderId: "358170192250",
          appId: "1:358170192250:web:2a95bbec0d4cb2bf2e0ec3",
          measurementId: "G-5Y1NFC2SX5"
        });

        return;
      }

      ky
        .get("/__/firebase/init.json", {
          retry: { limit: 3 },
          timeout: 3000,
        })

        .json().then(firebaseData => {
          setFirebaseConfig(firebaseData);
          console.log(`%cAmbiente detectado: %c${firebaseData.projectId}`, "font-weight: bold", "color: gray");
        })
        .catch(err => {
          console.log(`%cErro de ambiente: %c${err}`, "font-weight: bold; color: red", "color: gray");
          setFirebaseConfig(false);
        });
    } catch(err) {
      setFirebaseConfig(false);
      console.log(`%cErro ao tentar detectar ambiente: %c${err}`, "font-weight: bold; color: red", "color: gray");
    }
  }, []);

  let firebaseApp, firebaseAuth, db, storage, realtimeDb;

  if (firebaseConfig) {
    firebaseApp = firebase.initializeApp(firebaseConfig);

    // if (process.env.REACT_APP_RECAPTCHA_KEY) {
    //   const appCheck = firebase.appCheck();

    //   appCheck.activate(process.env.REACT_APP_RECAPTCHA_KEY, true);
    // }

    firebaseAuth = firebaseApp.auth();
    db = firebase.firestore(firebaseApp);
    db.settings({ ignoreUndefinedProperties: true, merge: true });
    storage = firebase.storage(firebaseApp);
    realtimeDb = firebase.database(firebaseApp);
  }

  const value = {
    firebaseConfig,
    firestore: firebase.firestore,
    firebaseApp,
    firebaseAuth,
    db,
    storage,
    realtimeDb,
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;

export { FirebaseProvider };
